import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ProjectTile from "./components/ProjectTile";
import ProjectWindow from "./components/ProjectWindow";
import BackButton from "./components/BackButton";

import {
  name,
  about,
  school,
  program,
  location,
  email,
  projects,
  work,
} from "./shadid.json";

function App() {
  return (
    <Router>
      <div className="contain-all">
        <div className="bg-wave">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#99CC99"
              fill-opacity="1"
              d="M0,288L26.7,272C53.3,256,107,224,160,197.3C213.3,171,267,149,320,117.3C373.3,85,427,43,480,58.7C533.3,75,587,149,640,186.7C693.3,224,747,224,800,218.7C853.3,213,907,203,960,181.3C1013.3,160,1067,128,1120,122.7C1173.3,117,1227,139,1280,133.3C1333.3,128,1387,96,1413,80L1440,64L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
            ></path>
          </svg>
          {/* <div id='wave-bottom'></div> */}
        </div>
        <div className="main-box">
          <Route
            path="/"
            exact
            render={(props) => (
              <div className="main-box-body">
                <h1>{name.toLowerCase()}</h1>
                <p>{about}</p>
                <ul className="about-me">
                  <li>🏫 {school}</li>
                  <li>📚 {program}</li>
                  <li>📍 {location}</li>
                  <li>📧 {email}</li>
                </ul>
                <button className="projects-button">
                  <a
                    href="https://www.youtube.com/watch?v=nM4Sb4iCZ2Q&list=PLRJIge5Z8iIrrW0ISIXtjT1LiQkLc7TcK&index=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AFTER EFFECTS PROJECTS
                  </a>
                </button>
                <button className="projects-button">
                  <Link className="link" to="/projects">
                    PROGRAMMING PROJECTS
                  </Link>
                </button>
                <button className="projects-button">
                  <Link className="link" to="/work">
                    WORK EXPERIENCE
                  </Link>
                </button>
              </div>
            )}
          />
          <Route
            path="/projects"
            exact
            render={(props) => (
              <div className="main-box-body">
                <BackButton to="/" />
                <h1>projects</h1>
                <div className="modal">
                  {projects.map((project) => (
                    <ProjectWindow project={project} key={project.id} />
                  ))}
                </div>
                <div className="projects">
                  {projects.map((project) => (
                    <ProjectTile project={project} key={project.id} />
                  ))}
                </div>
              </div>
            )}
          />
          <Route
            path="/work"
            exact
            render={(props) => (
              <div className="main-box-body">
                <BackButton to="/" />
                <h1>work experience</h1>
                <div className="modal">
                  {work.map((project) => (
                    <ProjectWindow project={project} key={project.id} />
                  ))}
                </div>
                <div className="projects">
                  {work.map((project) => (
                    <ProjectTile project={project} key={project.id} />
                  ))}
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
