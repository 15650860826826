import { useEffect } from "react";
import ReactMarkdown from "react-markdown";

const ProjectWindow = ({ project }) => {
  useEffect(() => {
    const modal = document.getElementsByClassName("modal")[0];
    const closeButton = document.getElementById(project.id + "-close");
    const outsideClick = (e) => {
      if (e.target === modal || e.target === closeButton) {
        modal.style.display = "none";
        document.getElementById(project.id + "-wdw").style.display = "none";
      }
    };

    window.addEventListener("click", outsideClick);
  });

  return (
    <div id={project.id + "-wdw"} className="project-window">
      <h3 id={project.id + "-close"} className="close-window">
        &times;
      </h3>
      <h3>
        <b>{project.name} -</b> {project.desc}
      </h3>
      <img src={project.images[0]} className="modal-img" alt=""></img>
      <footer>
        <div style={{ wordWrap: "break-word" }}>
          <ReactMarkdown>
            {/* {project.points.reduce((acc, cv) => acc + "\n* " + cv, "")} */}
            {project.body}
          </ReactMarkdown>
        </div>
        <div className="modal-btn">
          {project.links &&
            project.links.map((link, index) => (
              // <button formaction={link.href} name={link.label}>{link.label}</button>
              <a href={link.href} target="_blank" rel="noreferrer" key={index}>
                <button>{link.label}</button>
              </a>
            ))}
        </div>
      </footer>
    </div>
  );
};

export default ProjectWindow;
