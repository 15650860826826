const ProjectTile = ({ project }) => {

    const openWindow = () => {
        document.getElementsByClassName('modal')[0].style.display = 'flex';
        document.getElementById(project.id + '-wdw').style.display = 'flex';
    }

    return (
        <button id={project.id + '-btn'} className='tile' onClick={openWindow}>
            <div>
                {project.icon && <img alt={project.desc} src={project.icon}></img>}
                {project.name}
            </div>
        </button>
    )
}

export default ProjectTile;